// cannot import a .png
import splashLogo from './assets/logo2.png';

async function main() {
  const element = document.getElementById('splash');

  if (element == null) {
    throw new Error("Could not get element with id='splash'");
  }

  const img = document.createElement('img');
  const div = document.createElement('div');
  div.style.backgroundColor = '#03c991';
  div.style.width = '100vw';
  div.style.height = '100vh';
  div.style.top = '0';
  div.style.left = '0';
  div.style.display = 'flex';
  div.style.justifyContent = 'center'; /* align horizontal */
  div.style.alignItems = 'center'; /* align-vertical */

  div.style.zIndex = '10000';
  div.style.position = 'absolute'; /* align vertical  */

  img.style.maxWidth = '50vw';
  img.src = splashLogo;

  element.appendChild(div);
  div.appendChild(img);
  setTimeout(() => {
    div.style.display = 'none';
  }, 1000);

  setTimeout(() => {
    div.style.display = 'none';
  }, 1500);

  // https://rollupjs.org/tutorial/#code-splitting

  await import('react-dom');
  await import('firebase/auth');
  await import('firebase/firestore');
  await import('./index');
}

main();
